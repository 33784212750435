import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
	// 初始化默认根路由  重定向到login
	{
		path: '/',
		redirect: '/login',
	},
	//  登录页
	{
		path: '/login',
		name: 'index',
		component: () => import('@/views/login/index.vue')
	},

	// 数据大屏--全校大屏
	{
		path: '/bigScreen',// ../views/bigScreen/index
		name: 'index',
		component: () => import('@/views/bigScreen/index.vue'),
	},

	// 其他页面
	{
		path: '/error',
		name: '错误',
		component: () => import('../views/login/index.vue'),
	},
	{
		path: '/bindPhone', // openID=
		name: '绑定手机号',
		component: () => import('../views/other/bindPhone.vue'),
	},
	// 正规页面
	{
		path: '/home',
		name: 'Home',
		component: () => import('../views/home/index.vue'),
		children: [
			// 登录完成
			{
				path: '/loginDone', // token=
				name: '登录成功',
				component: () => import('../views/other/loginDone.vue'),
			},

			// 考核记录
			{
				path: '/recordManage',
				name: '记录管理',
				component: () => import('../views/sliderComponent/recordManage.vue'),
			},
			{
				path: '/classRecord',
				name: '班级记录',
				component: () => import('../views/sliderComponent/recordManageClass.vue'),
			},
			{
				path: '/studentRecord',
				name: '学生记录',
				component: () => import('../views/sliderComponent/recordManageStudent.vue'),
			},

			// 数据中心
			{
				path: '/departmentArgument',
				name: '全校数据',
				component: () => import('../views/sliderComponent/departmentArgument.vue'),
			},
			{
				path: '/schoolIndexSetting',
				name: '院系数据',
				component: () => import('../views/sliderComponent/schoolIndexSetting.vue'),
			},
			{
				path: '/classIndexSetting',
				name: '班级数据',
				component: () => import('../views/sliderComponent/classIndexSetting.vue'),
			},
			{
				path: '/studentIndexSetting',
				name: '学生数据',
				component: () => import('../views/sliderComponent/studentIndexSetting.vue'),
			},


			// 预警中心
			{
				path: '/informationCenter',
				name: '高分预警',
				component: () => import('../views/sliderComponent/informationCenter.vue'),
			},
			{
				path: '/scoreWarnHighClass',
				name: '班级高分预警',
				component: () => import('../views/sliderComponent/scoreWarnHighClass.vue'),
			},
			{
				path: '/scoreWarnLowClass',
				name: '班级低分预警',
				component: () => import('../views/sliderComponent/scoreWarnLowClass.vue'),
			},
			{
				path: '/scoreWarnHigh',
				name: '学生高分预警',
				component: () => import('../views/sliderComponent/scoreWarnHigh.vue'),
			},
			{
				path: '/scoreWarnLow',
				name: '学生低分预警',
				component: () => import('../views/sliderComponent/scoreWarnLow.vue'),
			},

			// 指标设置
			{
				path: '/stuIndexSetting',
				name: '班级指标',
				component: () => import('../views/sliderComponent/stuIndexSetting.vue'),
			},
			{
				path: '/indexSetting',
				name: '学生指标',
				component: () => import('../views/sliderComponent/indexSetting.vue'),
			},
			{
				path: '/classSectionSetting',
				name: '课节设置',
				component: () => import('../views/sliderComponent/classSectionSetting.vue'),
			},
			{
				path: '/scoreCategory',
				name: '评分类别管理',
				component: () => import('../views/manager/scoreCategory.vue'),
			},

			// 班级管理
			//{
			//  path: '/department',
			//  name: '院系管理',
			//  component: () => import('../views/manager/department.vue'),
			//},
			{
				path: '/grade',
				name: '院系班级',
				component: () => import('../views/manager/grade.vue'),
			},
			{
				path: '/teacherArgument',
				name: '教师管理',
				component: () => import('../views/sliderComponent/teacherArgument.vue'),
			},
			{
				path: '/userArgument',
				name: '学生管理',
				component: () => import('../views/sliderComponent/userArgument.vue'),
			},
			{
				path: '/department',
				name: '院系管理',
				component: () => import('../views/manager/department.vue'),
			},


			// 卡片管理
			{
				path: '/cardArgument',
				name: '卡片管理',
				component: () => import('../views/sliderComponent/cardArgument.vue'),
			},
			// 班级卡片
			{
				path: '/cardArgumentClass',
				name: '班级卡片',
				component: () => import('../views/sliderComponent/cardArgumentClass.vue'),
			},
			// 学生卡片
			{
				path: '/cardArgumentStudent',
				name: '学生卡片',
				component: () => import('../views/sliderComponent/cardArgumentStudent.vue'),
			},


			// 授权管理
			{
				path: '/permissionArgument',
				name: '菜单配置',
				component: () => import('../views/sliderComponent/permissionArgument.vue'),
			},


			// 信息中心
			{
				path: '/msgSend',
				name: '发送消息',
				component: () => import('../views/sliderComponent/msgSend.vue'),
			},
			{
				path: '/msgRecv',
				name: '接收消息',
				component: () => import('../views/sliderComponent/msgRecv.vue'),
			},

		]
	},
]


const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

export default router
