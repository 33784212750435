import FileSaver from 'file-saver';
import XLSX from 'xlsx';
export default {
	// 导出Excel表格
	exportExcel(name, tableName) {
		var xlsxParam = { raw: true };//转换成excel时，使用原始的格式
		// name表示生成excel的文件名     tableName表示表格的id
		var sel = XLSX.utils.table_to_book(document.querySelector(tableName),xlsxParam);
		var selIn = XLSX.write(sel, {
			bookType: 'xlsx',
			bookSST: true,
			type: 'array'
		})
		try {
			FileSaver.saveAs(new Blob([selIn], {
				type: 'application/octet-stream'
			}), name)
		} catch (e) {
			if (typeof console !== 'undefined') console.log(e, selIn)
		}
		return selIn
	}
};
