import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import axios from 'axios'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import './assets/common.scss'
import html from 'html2canvas'
import * as echarts from 'echarts'
require('echarts-wordcloud');
import moment from 'moment'

// 全局导出excel
import outToExcel from './excel/outToExcel.js';
Vue.prototype.outToExcel = outToExcel;


Vue.prototype.$store = store
Vue.prototype.$moment = moment
Vue.prototype.$echarts = echarts
Vue.use(html)
// 设置elemenet ui 全局尺寸 所有拥有size属性的组件的大小都为small，
// Vue.use(Element,{size:'small',zIndex:3000})
Vue.use(Element)
Vue.config.productionTip = false
const eventBus = new Vue()
Vue.prototype.$eventBus = eventBus
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.filter('dateYMDHMSFormat',function(dateStr,pattern='YYYY-MM-DD HH:mm:ss') {
  return moment(dateStr).format(pattern);
})
Vue.filter('dateYMDFormat',function(dateStr,pattern='YYYY-MM-DD') {
  return moment(dateStr).format(pattern);
})
Vue.filter('dateHMSFormat',function(dateStr,pattern='HH:mm:ss') {
  return moment(dateStr).format(pattern);
})

// 引入公用 css、js
// import '../static/css/lightbox.css'
// import '../../static/js/jquery-2.1.1.min.js'
// import '../../static/js/jquery.lightbox.js'
